import React from 'react';
const Signin2 = React.lazy(() => import('./HashTag/Authentication/SignIn'));
const resetPassword = React.lazy(() => import('./HashTag/Authentication/ResetPassword'));
const updatePassword = React.lazy(() => import('./HashTag/Authentication/UpdatePassword'));

const route = [
    { path: '/auth/login', exact: true, name: 'Login', component: Signin2 },
    { path: '/auth/resetpassword', exact: true, name: 'resetpassword', component: resetPassword },
    { path: '/auth/updatepassword/:id', exact: true, name: 'resetpassword', component: updatePassword },
];

export default route;